/* You can add global styles to this file, and also import other style files */
@import 'sveti-vid-theme.scss';
@import '~@ng-select/ng-select/themes/material.theme.css';
@import '../node_modules/angular-calendar/css/angular-calendar.css';

.card .card-header-info .card-icon,
.card .card-header-info .card-text,
.card .card-header-info:not(.card-header-icon):not(.card-header-text) {
  box-shadow:
    0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(33, 150, 243, 0.4);
}
.card.bg-info,
.card .card-header-info .card-icon,
.card .card-header-info .card-text,
.card .card-header-info:not(.card-header-icon):not(.card-header-text),
.card.card-rotate.bg-info .back,
.card.card-rotate.bg-info .front {
  background: linear-gradient(60deg, #42a5f5, #1e88e5);
}
.sidebar[data-color='azure'] li.active > a {
  background-color: #2196f3;
  box-shadow:
    0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(33, 150, 243, 0.4);
}
.navbar.bg-info .dropdown-item:focus,
.navbar.bg-info .dropdown-item:hover {
  box-shadow:
    0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(33, 150, 243, 0.4);
  background-color: #2196f3;
}
.h-100vh {
  height: 100vh;
}
.mh-100px {
  max-height: 100px;
}
.mw-400px {
  max-width: 400px;
}
.cursor-pointer {
  cursor: pointer;
}
.pt-20 {
  padding-top: 20px;
}

//Stilovi za ng-select direktivu
ng-select.ng-invalid.ng-touched .ng-select-container .ng-placeholder,
ng-select.ng-invalid.ng-touched .ng-select-container span.ng-arrow {
  color: red;
}
ng-select.ng-invalid.ng-touched .ng-select-container::after {
  border-bottom-color: red;
}
ng-select span {
  font-weight: 400;
}

::ng-deep .green-snackbar {
  background-color: #4caf50;
}

::ng-deep .red-snackbar {
  background-color: #f44336;
}

@media print {
  @page {
    size: A4 portrait; /* or adjust as needed */
    margin: 0; /* Adjust margin as needed */
  }

  body {
    margin: 0;
    visibility: hidden;
  }

  .printable {
    position: relative;
    margin-top: 950px;
    width: 100vw; /* Set to viewport width */
    height: 100vh; /* Set to viewport height */
    overflow: hidden;
    transform: rotate(-90deg) scale(1.2); /* Rotate content */
    transform-origin: top left;
  }

  .printable .day-container,
  .printable .week-container,
  .printable .month-container {
    width: 100%; /* Full width of the printable area */
    height: 100%; /* Full height of the printable area */
    overflow: visible; /* Ensure no overflow */
  }
}

//DayPilotCalendar styles
body .month_default_event {
  min-height: 20px;
  max-height: 33px;
}

body .month_default_event_inner {
  background: #2e78d6;
  //color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 9.5px;
  padding: 5px;
  opacity: 0.8;
  line-height: 1.3;
}

// body .month_default_cell {
//   height: 120px !important;
// }

// body .month_default_cell:nth-child(6n + 3) {
//   margin-top: 20px !important;
//   .month_default_event {
//     margin-top: 20px !important;
//   }
// }

// body .month_default_cell:nth-child(6n + 4) {
//   margin-top: 40px !important;
// }

// body .month_default_cell:nth-child(6n + 5) {
//   margin-top: 60px !important;
// }

// body .month_default_cell:nth-child(6n + 6) {
//   margin-top: 80px !important;
// }

// body .month_default_cell:nth-child(6n + 7) {
//   margin-top: 100px !important;
// }

// body .month_default_main {
//   height: 630px !important;
// }
