@use '@angular/material' as mat;
@import '@angular/material/theming';
/* Plus imports for other components in your app.

Include the common styles for Angular Material. We include this here so that you only
have to load a single css file for Angular Material in your app.
Be sure that you only ever include this mixin once! */
@include mat.core();

/* Define the palettes for your theme using the Material Design palettes available in palette.scss
(imported above). For each palette, you can optionally specify a default, lighter, and darker
hue. Available color palettes: https://material.io/design/color/ */
$sveti-vid-primary: mat.define-palette(mat.$blue-palette);
$sveti-vid-accent:  mat.define-palette(mat.$indigo-palette, A200, A100, A400);

/* The warn palette is optional (defaults to red). */
$sveti-vid-warn:    mat.define-palette(mat.$red-palette);

/* Create the theme object (a Sass map containing all of the palettes). */
$sveti-vid-theme: mat.define-light-theme($sveti-vid-primary, $sveti-vid-accent, $sveti-vid-warn);

/* Include theme styles for core and each component used in your app.
Alternatively, you can import and @include the theme mixins for each component
that you are using. */
@include mat.all-component-themes($sveti-vid-theme);